import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
Vue.config.productionTip = false
import 'vant/lib/button/style';
import 'vant/lib/popup/style';

import { Icon } from 'vant';
import { Popup } from 'vant';


Vue.use(Icon);
Vue.use(Popup);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
