import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'turnpage',
    component: () => import('../views/TurnPage')
  }
]

const router = new VueRouter({
  base: "/turnpage-two/",
  routes
})

export default router
